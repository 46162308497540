<template>
  <v-dialog
    v-model="isVisible"
    scrollable
    max-width="960px"
    :hide-overlay="true"
    :persistent="true"
    :no-click-animation="true"
  >
    <v-card
      v-if="currentRecord"
      ref="draggableModal"
      max-height="60%"
      max-width="70%"
      @mousedown="dragStart"
      @mousemove="dragging"
    >
      <v-card-title>
        <div>
          <div class="darkblue--text subtitle-2">
            {{ currentRecord.lineItemName }}
          </div>

          <div class="coolgray--text overline">
            <span class="font-weight-bold pr-1">ID</span>
            {{ currentRecord.lineItemCode }}
          </div>
        </div>

        <v-spacer />

        <v-btn
          :ripple="false"
          icon
          @click.stop="close()"
        >
          <v-icon color="brightblue">
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider class="mb-6" />

      <v-card-text>
        <div
          v-if="isLoadingDiscrepancyCreatives"
          class="loader"
        >
          <v-progress-circular
            :size="70"
            color="#055289"
            indeterminate
          />
        </div>

        <table v-if="isLoadingDiscrepancyCreatives === false && didLoadDiscrepancyCreativesFail === false && discrepancyCreatives.length > 0">
          <thead>
            <th>Server</th>
            <th>Creative Id</th>
            <th>Creative Name</th>
            <th>3rd Party Imps</th>
            <th>3rd Party Clicks</th>
            <th>1st Party Imps</th>
            <th>1st Party Clicks</th>
            <th>Discrepancy</th>
          </thead>
          <tbody>
            <tr
              v-for="creativeItem of discrepancyCreatives"
              :key="creativeItem.creativeCode"
            >
              <td class="creative-server">
                {{ creativeItem.thirdPartyServer }}
              </td>

              <td class="creative-id">
                <a
                  :href="transform.mapCreativeLink(currentRecord.networkCode, creativeItem.creativeCode)"
                  target="_blank"
                  title="Open Creative in Google Ad Manager"
                >
                  {{ creativeItem.creativeCode }}
                </a>
              </td>

              <td class="creative-name">
                {{ creativeItem.creativeName }}
              </td>
              <td class="column-number creative-third-party-impressions">
                {{ format.number(creativeItem.thirdPartyImps) }}
              </td>
              <td class="column-number creative-third-party-clicks">
                {{ format.number(creativeItem.thirdPartyClicks) }}
              </td>
              <td class="column-number creative-local-impressions">
                {{ format.number(creativeItem.localImps) }}
              </td>
              <td class="column-number creative-local-clicks">
                {{ format.number(creativeItem.localClicks) }}
              </td>
              <td
                :class="'td creative-discrepancy-percentage '
                  + getDiscrepancyClassName(creativeItem.discrepancyPercentage)"
              >
                {{ formatDiscrepancyPercentage(creativeItem.discrepancyPercentage) }}
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-if="isLoadingDiscrepancyCreatives === false && didLoadDiscrepancyCreativesFail === false && discrepancyCreatives.length === 0"
          class="empty-state"
        >
          No creatives could be found for selected line item.
        </div>
        <div
          v-if="didLoadDiscrepancyCreativesFail"
          class="error-message"
        >
          <v-icon>error_outline</v-icon>
          Failed to load creatives. Please try again later.
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import discrepancy from 'src/delivery/store/utils/discrepancy'
import draggable from 'src/utils/draggable'
import format from 'src/utils/format'
import transform from 'src/utils/transform'
import osi from '../../store/utils/osi'

const { mapState: mapDeliveryState, mapActions } = createNamespacedHelpers('delivery')
const { mapGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'DiscrepancyCreativeModal',
  data: () => ({
    dragger: {},
    loading: false,
    format,
    MAX_INT: '2147483647',
    transform,
    osi,
  }),
  computed: {
    isVisible: {
      get() {
        return this.isDiscrepancyCreativeModalVisible
      },
      set(value) {
        this.$store.commit('delivery/isDiscrepancyCreativeModalVisible', value)
      },
    },
    ...mapGetters([
      'currentColumns',
    ]),
    ...mapToolsState({
      publisherId: (state) => state.publisherId,
      reportId: (state) => state.reportId,
      subId: (state) => state.subId,
      teamId: (state) => state.teamId,
    }),
    ...mapDeliveryState({
      currentRecord: (state) => state.currentRecord,
      isDiscrepancyCreativeModalVisible: (state) => state.isDiscrepancyCreativeModalVisible,
      isLoadingDiscrepancyCreatives: (state) => state.isLoadingDiscrepancyCreatives,
      didLoadDiscrepancyCreativesFail: (state) => state.didLoadDiscrepancyCreativesFail,
      discrepancyCreatives: (state) => state.discrepancyCreatives,
    }),
  },
  watch: {
    currentRecord(newValue) {
      if (newValue) {
        const { publisherId, teamId } = this
        const params = { ...newValue, publisherId, teamId }

        this.getDiscrepancyCreatives(params)
      }
    },
  },
  created() {
    document.addEventListener('mouseup', this.finishDragging)
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.finishDragging)
  },
  methods: {
    ...mapActions(['getDiscrepancyCreatives']),
    ...draggable,

    getDiscrepancyClassName(value) {
      return discrepancy.getDiscrepancyClassName(value)
    },

    close() {
      this.isVisible = false
    },

    formatDiscrepancyPercentage(discrepancyPercentage) {
      return format.discrepancyPercentage(discrepancyPercentage)
    },
  },
}
</script>

// @TODO: [PLAN-415] Abstract redundant modal styles to a styles file.
<style lang="scss" scoped>
  @import "~src/styles/variables";

  ::v-deep.v-card {
    border-radius: 0.375rem;
    border-width: 0;
    position: fixed;
    left: 15%;
    top: 20%;
  }
  ::v-deep.v-card__title {
    color: $color-monochrome-darkestblue;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25rem;
    justify-content: space-between;
  }

  ::v-deep.v-card__text {
    padding: 0;
  }

  .title-text {
    flex: 1;
  }

  table {
    width: 100%;
    border-spacing: 0;
  }

  th {
    top: 0px;
    position: sticky;
    position: -webkit-sticky;
    text-transform: uppercase;
    border-bottom: 0.0625rem solid #F2EFEF;
    background: $color-monochrome-white;

    color: #515151;
    font-size: 0.6875rem;
    font-weight: 600;
    line-height: 0.75rem;

    text-align: left;
    padding-right: 0.75rem;

    &:first-of-type {
      padding-left: 1.5rem;
    }
  }

  tbody tr {
    height: 3.75rem;

    &:nth-child(even) {
      background-color: #FBFBFC;
    }
  }

  td {
    color: $color-monochrome-gray;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 0.8125rem;
    padding-right: 1.5rem;

    &:first-of-type {
      padding-left: 1.5rem;
    }
  }

  ::v-deep.creative-server {
    min-width: 112px;
    max-width: 112px;
  }

  td.creative-id {
    min-width: 96px;
    max-width: 96px;
  }

  td.creative-name {
    min-width: 250px;
    max-width: 250px;
  }

  td.creative-third-party-impressions {
    min-width: 75px;
    max-width: 75px;
  }

  td.creative-third-party-clicks {
    min-width: 75px;
    max-width: 75px;
  }

  td.creative-local-impressions {
    min-width: 75px;
    max-width: 75px;
  }

  td.creative-local-clicks {
    min-width: 75px;
    max-width: 75px;
  }

  td.creative-discrepancy-percentage {
    min-width: 75px;
    max-width: 75px;
  }

  .discrepancy {
    font-weight: bold;

    &.discrepancy-0 {
      color: $color-legend-neutral;
    }

    &.discrepancy-between-1-10 {
      color: $color-legend-yellow;
    }

    &.discrepancy-between-minus-1-10 {
      color: $color-legend-green;
    }

    &.discrepancy-beyond-10 {
      color: $color-legend-red;
    }

    &.discrepancy-beyond-minus-10 {
      color: $color-legend-blue;
    }
  }
</style>
